import LanguageSelect from "@cospex/client/components/LanguageSelect";
import useAuth from "@cospex/client/hooks/useAuth";
import useTranslation from "@cospex/client/hooks/useTranslation";
import { Dashboard, Logout, PermIdentity } from "@mui/icons-material";
import {
  Box,
  Container,
  Stack,
  Tab,
  Tabs,
  Theme,
  useMediaQuery,
} from "@mui/material";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

const getIndex = (path: string) => {
  if (path.includes("parcels")) {
    return 0;
  }
  if (path.includes("account")) {
    return 1;
  }
};

interface IDashboardLayout {
  languages: LanguageSelectOptions;
}

export default function DashboardLayout({ languages }: IDashboardLayout) {
  const navigate = useNavigate();
  const { signout } = useAuth();
  const location = useLocation();
  const index = getIndex(location.pathname);
  const { t } = useTranslation();
  const md = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"));
  const sm = useMediaQuery((theme: Theme) => theme.breakpoints.up("sm"));

  const tabStyle = {
    whiteSpace: "nowrap",
    display: "flex",
    flexDirection: "row",
    gap: 2,
    color: "black",
    justifyContent: md ? "flex-start" : "center",
    opacity: 1,
  };

  return (
    <Container
      sx={{
        pt: 4,
        pb: 8,
      }}
    >
      <Stack direction={md ? "row" : "column"}>
        <Tabs
          value={index}
          aria-label="nav tabs example"
          orientation={md ? "vertical" : "horizontal"}
          scrollButtons
          allowScrollButtonsMobile
          variant="scrollable"
          sx={{
            mx: {
              md: 0,
              xs: -4,
            },
            mb: {
              md: 0,
              xs: 2,
            },
          }}
        >
          <Tab
            icon={<Dashboard />}
            label={sm ? t("dashboard-overview") : ""}
            value={0}
            sx={tabStyle}
            onClick={() => {
              navigate("/dashboard/parcels");
            }}
          />
          <Tab
            icon={<PermIdentity />}
            label={sm ? t("dashboard-my-account") : ""}
            value={1}
            sx={tabStyle}
            onClick={() => {
              navigate("/dashboard/account");
            }}
          />
          <Tab
            icon={<Logout />}
            label={sm ? t("dashboard-logout") : ""}
            value={2}
            sx={tabStyle}
            onClick={() => {
              signout();
              navigate("/");
            }}
          />
          <Box display="flex" alignItems="center" justifyContent="center">
            <LanguageSelect options={languages} />
          </Box>
        </Tabs>
        <Box
          sx={{
            pl: {
              md: 4,
              xs: 0,
            },
            flex: 1,
          }}
        >
          <Outlet />
        </Box>
      </Stack>
    </Container>
  );
}
