import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: "smooth" });
};

export const useScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    scrollToTop();
  }, [pathname]);
};

export const ScrollToTop = () => {
  useScrollToTop();
  return null;
};
