import ContactFormNumber from "@cospex/client/components/ContactFormNumber";
import useTranslation from "@cospex/client/hooks/useTranslation";
import CenteredCard, {
  CenteredCardSubmit,
  CenteredCardSuccessBox,
  CenteredCardTitle,
} from "@cospex/client/parcel/components/CenteredCard";
import { Label } from "@cospex/client/parcel/components/Typography";
import { zodResolver } from "@hookform/resolvers/zod";
import { Alert, Stack } from "@mui/material";
import TextField from "@mui/material/TextField";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { Controller, useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { z } from "zod";

const validationSchema = z.object({
  email: z.string().nonempty("required").email("login-email-invalid"),
  firstname: z.string().nonempty("required"),
  lastname: z.string().nonempty("required"),
  message: z.string().nonempty("required"),
});

type ContactData = {
  email: string;
  firstname: string;
  lastname: string;
  message: string;
};

const Contact = () => {
  const { t } = useTranslation();
  const { control, handleSubmit } = useForm<ContactData>({
    resolver: zodResolver(validationSchema),
  });
  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const { i18n } = useTranslation();
  const [messageSent, setMessageSent] = useState(false);
  const [messageError, setMessageError] = useState("");

  const mutation = useMutation({
    mutationFn: async (variables: {
      firstname: string;
      lastname: string;
      email: string;
      message: string;
    }) => {
      const recaptcha = await recaptchaRef.current?.executeAsync();
      return axios.post(
        "/api/contact",
        {
          formData: {
            firstname: variables.firstname,
            lastname: variables.lastname,
            email: variables.email,
            message: variables.message,
            "g-recaptcha-response": recaptcha,
          },
          language: i18n.language,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    },
    onSuccess: () => {
      setMessageSent(true);
    },
    onError: (error: { message: string }) => {
      setMessageError(error.message);
    },
  });

  const autoError = (message: string) => {
    return message.toLocaleLowerCase();
  };

  return (
    <CenteredCard>
      <CenteredCardSuccessBox
        show={messageSent}
        textComponent={
          <>
            {t("contact-message-sent")}
            <br />
            {t("contact-message-wait")}
          </>
        }
      />
      <Label>{t("contact-label")}</Label>
      <CenteredCardTitle text={t("contact-title")} />
      <ContactFormNumber />
      <Alert severity="info" sx={{ mt: 2 }}>
        {t("contact-disclaimer-a")}{" "}
        <Link to="/reset-password">{t("contact-disclaimer-b")}</Link>.
      </Alert>

      <form
        onSubmit={handleSubmit((data: ContactData) => mutation.mutate(data))}
      >
        <Stack spacing={2} sx={{ width: "100%" }} mt={2}>
          <Controller
            name="email"
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                label={t("login-email")}
                error={!!fieldState.error}
                helperText={
                  fieldState.error?.message &&
                  t(autoError(fieldState.error?.message))
                }
                variant="outlined"
                fullWidth
              />
            )}
          />
          <Controller
            name="firstname"
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                label={t("contact-firstname")}
                error={!!fieldState.error}
                helperText={
                  fieldState.error?.message &&
                  t(autoError(fieldState.error?.message))
                }
                variant="outlined"
                fullWidth
              />
            )}
          />
          <Controller
            name="lastname"
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                label={t("contact-lastname")}
                error={!!fieldState.error}
                helperText={
                  fieldState.error?.message &&
                  t(autoError(fieldState.error?.message))
                }
                variant="outlined"
                fullWidth
              />
            )}
          />
          <Controller
            name="message"
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                label={t("contact-message")}
                error={!!fieldState.error}
                helperText={
                  fieldState.error?.message &&
                  t(autoError(fieldState.error?.message))
                }
                variant="outlined"
                fullWidth
                multiline
                rows={4}
              />
            )}
          />
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey={import.meta.env.VITE_GOOGLE_RECAPTCHA_SITE_KEY}
            size="invisible"
            style={{
              margin: 0,
            }}
          />
          <CenteredCardSubmit
            loading={mutation.isLoading}
            text={t("contact-message-send")}
          />
          {messageError && <Alert severity="error">{messageError}</Alert>}
        </Stack>
      </form>
    </CenteredCard>
  );
};

export default Contact;
