import { getToken } from "@cospex/client/context/AuthProvider";
import useTranslation from "@cospex/client/hooks/useTranslation";
import { FloatingCard } from "@cospex/client/parcel/components/Layout";
import {
  Alert,
  Box,
  Divider,
  Grid,
  Skeleton,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";

const RowItem = styled(Typography)<{ label: string }>`
  &:before {
    font-weight: bold;
    content: "";
    ${({ theme }) => theme.breakpoints.down("sm")} {
      content: "${({ label }) => label}: ";
    }
  }
`;

const testTrack: TrackingObject = {
  shipments: [
    {
      states: [
        {
          location: "Chalong, Phuket",
          date: "2023-05-29T14:49:03Z",
          carrier: 0,
          status: "Successfully delivered",
        },
        {
          location: "Chalong, Phuket",
          date: "2023-05-29T14:48:00Z",
          carrier: 0,
          status: "Courier has contacted the recipient",
        },
        {
          location: "Chalong, Phuket",
          date: "2023-05-29T13:42:35Z",
          carrier: 0,
          status: "Out for delivery",
        },
        {
          location: "Chalong, Phuket",
          date: "2023-05-29T13:18:53Z",
          carrier: 0,
          status: "Arrived at the destination station",
        },
        {
          location: "Phuket, Phuket",
          date: "2023-05-29T12:14:12Z",
          carrier: 0,
          status: "Departed from Distribution center",
        },
        {
          location: "Phuket, Phuket",
          date: "2023-05-29T11:59:58Z",
          carrier: 0,
          status: "Arrived at Distribution center",
        },
        {
          location: "Bangkok, Bangkok",
          date: "2023-05-28T20:39:24Z",
          carrier: 0,
          status: "Departed from Distribution center",
        },
        {
          location: "Bangkok, Bangkok",
          date: "2023-05-28T09:41:25Z",
          carrier: 0,
          status: "Arrived at Distribution center",
        },
        {
          location: "Sansai, Chiangrai",
          date: "2023-05-27T17:04:27Z",
          carrier: 0,
          status: "Departed from Distribution center",
        },
        {
          location: "Wiengpangkam, Chiangrai",
          date: "2023-05-27T12:06:37Z",
          carrier: 0,
          status: "Departed from Origin station",
        },
        {
          location: "Wiengpangkam, Chiangrai",
          date: "2023-05-27T10:02:02Z",
          carrier: 0,
          status: "Picked up by courier",
        },
        {
          location: "Chiangrai",
          date: "2023-05-26T14:24:31Z",
          carrier: 0,
          status: "Your parcel has been dropped off at service point",
        },
      ],
      destination: "Thailand",
      delivered_by: "2023-05-29T00:00:00Z",
      carriers: ["Kerry Express Thailand"],
      externalTracking: [
        {
          slug: "kerry-express-th",
          url: "https://th.kerryexpress.com/en/track/?track=KERDO0097996090",
          method: "GET",
          title: "Kerry Express Thailand",
        },
      ],
      status: "delivered",
      destinationCode: "TH",
      services: [{ slug: "kerry-express-th", name: "Kerry Express Thailand" }],
      trackingId: "KERDO0097996090",
      detectedCarrier: {
        slug: "kerry-express-th",
        name: "Kerry Express Thailand",
      },
      lastState: {
        location: "Chalong, Phuket",
        date: "2023-05-29T14:49:03Z",
        carrier: 0,
        status: "Successfully delivered",
      },
      attributes: [
        { l: "tracking_id", val: "KERDO0097996090" },
        { l: "destination", val: "Thailand", code: "TH" },
        { l: "recipient", val: "Johnathan Bigbooty" },
        { l: "sender", val: "XGST***" },
      ],
    },
  ],
  done: true,
  fromCache: true,
};

const toDateString = (date: string) => {
  const d = new Date(date);
  return d.toLocaleDateString();
};

interface IParcelInfo {
  id: string;
}

export default function ParcelInfo({ id }: IParcelInfo) {
  const { t } = useTranslation();
  const isTest = id === "test-id";

  const { isLoading, error, data } = useQuery({
    enabled: !isTest,
    queryKey: ["parcelData", id],
    queryFn: () =>
      axios
        .get<any>("/api/parcel/" + id + "/status", {
          withCredentials: true,
          headers: { Authorization: "Bearer " + getToken() },
        })
        .then((res) => res.data),
  });

  let trackingObject: TrackingObject | undefined;
  if (isTest) {
    trackingObject = testTrack;
  } else {
    if (data) {
      trackingObject = JSON.parse(data);
    }
  }

  const parcel = trackingObject?.shipments[0];
  return (
    <>
      <Typography variant="h2" mb={3}>
        {t("dashboard-parcel-info-title")}
      </Typography>
      {(() => {
        if (!isTest && isLoading) {
          return (
            <>
              <Skeleton
                width="100%"
                height={87}
                variant="rectangular"
                sx={{ mb: 2 }}
              />
              <Skeleton
                width="100%"
                height={87}
                variant="rectangular"
                sx={{ mb: 2 }}
              />
              <Skeleton
                width="100%"
                height={87}
                variant="rectangular"
                sx={{ mb: 2 }}
              />
            </>
          );
        }

        if (error) {
          return (
            <Alert severity="error">
              There has been a problem getting your parcel. Please try again
              later, if the problem persists, please contact us.
            </Alert>
          );
        }

        if (!parcel) {
          return (
            <Alert>
              There is no parcel with this id. Please check the id and try
              again.
            </Alert>
          );
        }

        if (Object.keys(parcel).length === 0) {
          return (
            <Alert severity="warning">
              Tracking information for this number is not currently available.
              If the number is correct, it will appear here once it has been
              scanned by the carrier.
            </Alert>
          );
        }

        return (
          <>
            <FloatingCard sx={{ marginBottom: 5 }}>
              <Typography variant="h3" color="primary.main" mb={3}>
                {t("dashboard-parcel-info-general-title")}
              </Typography>
              <Grid container spacing={1}>
                <Grid item xs={12} md={4}>
                  <Stack gap={4}>
                    <Typography variant="body1">
                      <strong>{t("dashboard-parcel-number-label")}</strong>:{" "}
                      <br />
                      {parcel.trackingId}
                    </Typography>
                    <Typography variant="body1">
                      <strong>{t("dashboard-parcel-info-label-from")}</strong>:{" "}
                      <br />
                      {parcel.states[parcel.states.length - 1].location}
                    </Typography>
                    <Typography variant="body1">
                      <strong>{t("dashboard-parcel-info-label-to")}</strong>:{" "}
                      <br />
                      {parcel.destination}
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Stack gap={4}>
                    <Typography variant="body1">
                      <strong>
                        {t("dashboard-parcel-info-label-dateCreated")}
                      </strong>
                      : <br />
                      {toDateString(
                        parcel.states[parcel.states.length - 1].date
                      )}
                    </Typography>
                    <Typography variant="body1">
                      <strong>
                        {t("dashboard-parcel-info-label-dateUpdated")}
                      </strong>
                      : <br />
                      {toDateString(parcel.lastState.date)}
                    </Typography>
                    {parcel.delivered_by && (
                      <Typography variant="body1">
                        <strong>
                          {t("dashboard-parcel-info-label-dateDelivered")}
                        </strong>
                        : <br />
                        {toDateString(parcel.delivered_by)}
                      </Typography>
                    )}
                  </Stack>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Stack gap={4}>
                    <Typography variant="body1">
                      <strong>{t("dashboard-parcel-info-label-status")}</strong>
                      : <br />
                      <span style={{ textTransform: "capitalize" }}>
                        {parcel.status}
                      </span>
                    </Typography>
                    <Typography variant="body1">
                      <strong>
                        {t("dashboard-parcel-info-label-carrier")}
                      </strong>
                      : <br />
                      {parcel.carriers.at(-1)}
                    </Typography>
                  </Stack>
                </Grid>
              </Grid>
            </FloatingCard>
            <FloatingCard sx={{ marginBottom: 5 }}>
              <Typography variant="h3" color="primary.main" mb={3}>
                {t("dashboard-parcel-info-shipment-title")}
              </Typography>
              <Stack gap={1.5}>
                <Grid
                  container
                  spacing={1}
                  sx={{
                    display: {
                      xs: "none",
                      sm: "flex",
                    },
                  }}
                >
                  <Divider sx={{ mb: 3 }} />
                  <Grid item xs={12} sm={1} />
                  <Grid item xs={12} sm={3}>
                    <Typography variant="body1">
                      <strong>
                        {t("dashboard-parcel-info-label-carrier")}
                      </strong>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Typography variant="body1">
                      <strong>{t("dashboard-parcel-info-label-status")}</strong>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <Typography variant="body1">
                      <strong>{t("dashboard-parcel-info-label-date")}</strong>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <Typography variant="body1">
                      <strong>
                        {t("dashboard-parcel-info-label-location")}
                      </strong>
                    </Typography>
                  </Grid>
                </Grid>
                <Divider />
                {parcel.states.reverse().map((entry, i) => (
                  <>
                    <Grid
                      container
                      key={entry.date + i}
                      spacing={1}
                      width="100%"
                    >
                      <Grid item xs={12} sm={1}>
                        <Box
                          sx={{
                            width: 32,
                            height: 32,
                            borderRadius: 32,
                            backgroundColor: "primary.main",
                            color: "white",
                            fontweight: 600,
                          }}
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                        >
                          {i + 1}
                        </Box>
                        {i !== parcel!.states.length - 1 && (
                          <Box
                            sx={{
                              height: "calc(100% - 48px)",
                              width: "1px",
                              background: "grey ",
                              transform: "translate(16px, 14px)",
                              display: {
                                md: "block",
                                sm: "none",
                              },
                            }}
                          />
                        )}
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <RowItem
                          variant="body1"
                          label={t("dashboard-parcel-info-label-carrier")}
                        >
                          {parcel.carriers[entry.carrier]}
                        </RowItem>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <RowItem
                          variant="body1"
                          label={t("dashboard-parcel-info-label-status")}
                        >
                          {entry.status}
                        </RowItem>
                      </Grid>
                      <Grid item xs={12} sm={2}>
                        <RowItem
                          variant="body1"
                          label={t("dashboard-parcel-info-label-date")}
                        >
                          {toDateString(entry.date)}
                        </RowItem>
                      </Grid>
                      <Grid item xs={12} sm={2}>
                        <RowItem
                          variant="body1"
                          label={t("dashboard-parcel-info-label-location")}
                        >
                          {entry.location}
                        </RowItem>
                      </Grid>
                    </Grid>
                  </>
                ))}
              </Stack>
            </FloatingCard>
          </>
        );
      })()}
    </>
  );
}
