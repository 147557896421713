import ReviewCarousel from "@cospex/client/components/ReviewCarousel";
import PaymentForm from "@cospex/client/forms/payment";
import { getItemWithExpiry } from "@cospex/client/helpers";
import useTranslation from "@cospex/client/hooks/useTranslation";
import {
  FloatingCard,
  Section,
  SectionSubtitle,
  SectionTitle,
} from "@cospex/client/parcel/components/Layout";
import Subscription from "@cospex/client/parcel/components/Subscription";
import LocationImage from "@cospex/client/parcel/img/location.png";
import LocationPinImage from "@cospex/client/parcel/img/location_pin.svg";
import { CheckCircleOutline } from "@mui/icons-material";
import {
  Alert,
  Box,
  Card,
  Container,
  Grid,
  Link,
  Stack,
  Typography,
  styled,
} from "@mui/material";

const AnimatedPin = styled(Box)`
  @keyframes bounce {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
    100% {
      transform: translateY(0);
    }
  }
  animation: bounce 1s ease-in-out infinite;
`;

const Payment = () => {
  const { t } = useTranslation();
  const trackingNumber = getItemWithExpiry("onboardingNumber");
  const email = getItemWithExpiry("onboardingEmail");
  const id = getItemWithExpiry("onboardingId");

  const noDetailsError = !trackingNumber || !email || !id;

  if (noDetailsError) {
    return (
      <Container>
        <Alert severity="error">
          We could not find a valid tracking number and email. Please visit the{" "}
          <Link href="/">home page</Link> and try tracking again.
        </Alert>
      </Container>
    );
  }

  return (
    <>
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          gap={1.5}
          mb={5}
          justifyContent="center"
          sx={{
            fontSize: "1.1rem",
            mb: {
              md: 6,
              sm: 4,
              xs: 2,
            },
          }}
        >
          <CheckCircleOutline fontSize="large" sx={{ color: "#00B448" }} />
          <Box
            sx={{
              display: {
                md: "block",
                sm: "none",
                xs: "none",
              },
            }}
          >
            {t("payment-step-1")}
          </Box>
          <Box
            sx={{
              width: 50,
              height: "1px",
              background: "grey",
              display: {
                md: "block",
                sm: "none",
                xs: "none",
              },
            }}
          />
          <Box
            sx={{
              width: 35,
              height: 35,
              border: "1px solid grey",
              borderRadius: 35,
            }}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            2
          </Box>
          <Box
            sx={{
              display: {
                md: "block",
                sm: "block",
                xs: "none",
              },
            }}
          >
            {t("payment-step-2")}
          </Box>
          <Box
            sx={{
              width: 50,
              height: "1px",
              background: "grey",
              display: {
                md: "block",
                sm: "none",
                xs: "none",
              },
            }}
          />
          <Box
            sx={{
              width: 35,
              height: 35,
              border: "1px solid grey",
              borderRadius: 35,
              opacity: 0.3,
            }}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            3
          </Box>
          <span style={{ opacity: 0.3 }}>{t("payment-step-3")}</span>
        </Stack>

        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <FloatingCard elevation={2} sx={{ p: 0 }}>
              <PaymentForm onPaymentSuccessRedirectPath="/dashboard/parcels" />
            </FloatingCard>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card variant="outlined">
              <Box
                sx={{
                  textAlign: "center",
                  mb: 2,
                }}
              >
                <Typography variant="h3">{t("parcel-found")}</Typography>
              </Box>
              <Box
                margin="auto"
                display="block"
                width="100%"
                sx={{
                  height: 200,
                  backgroundImage: `url(${LocationImage})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  borderRadius: 2,
                  position: "relative",
                }}
                mb={4}
              >
                <AnimatedPin
                  sx={{
                    backgroundImage: `url(${LocationPinImage})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "none",
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    right: 0,
                    top: -60,
                    margin: "auto",
                    width: 85 / 1.5,
                    height: 115 / 1.5,
                  }}
                />
              </Box>
              <Typography variant="h4" mb={2}>
                {t("package-location-graphic-title")}{" "}
                <Stack sx={{ display: "inline", color: "success.main" }}>
                  (#{trackingNumber})
                </Stack>
                :
              </Typography>
              <Typography variant="body1">
                Email: <b>{email}</b>
              </Typography>
              <Grid container mb={5.5}>
                <Grid item xs={6}>
                  <Typography variant="body1">
                    {t("package-location-graphic-address")}: xxxxx
                  </Typography>
                  <Typography variant="body1">
                    {t("package-location-graphic-country")}: xxxxx
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">
                    {t("package-location-graphic-coordinates")}: xxxxx
                  </Typography>
                  <Typography variant="body1">
                    {t("package-location-graphic-city")}: xxxxx
                  </Typography>
                </Grid>
              </Grid>
              <Typography variant="body2" mt={2}>
                {t("package-location-graphic-description")}
              </Typography>
            </Card>
          </Grid>
        </Grid>
      </Container>
      <Section filled noPaddingBottom>
        <SectionSubtitle>{t("home-testimonials-subtitle")}</SectionSubtitle>
        <SectionTitle>{t("home-testimonials-title")}</SectionTitle>
        <ReviewCarousel />
      </Section>
      <Section>
        <SectionSubtitle>
          {t("payment-pricing-description-subtitle")}
        </SectionSubtitle>
        <SectionTitle>{t("payment-pricing-description-title")}</SectionTitle>
        <Typography variant="body2" textAlign="center">
          {t("payment-pricing-description-text")}
        </Typography>
        <Subscription />
      </Section>
    </>
  );
};

export default Payment;
