import usePrices from "@cospex/client/hooks/usePrices";
import useTranslation from "@cospex/client/hooks/useTranslation";
import { FloatingCard } from "@cospex/client/parcel/components/Layout";
import { CheckCircle } from "@mui/icons-material";
import { Box, Button, Stack, Typography, styled } from "@mui/material";

const CheckCircleBlue = styled(CheckCircle)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: "1.2rem",
}));

const ListItem = ({ label }: { label: string }) => {
  return (
    <Stack
      sx={{
        flexDirection: "row",
        alignItems: "center",
        columnGap: "0.6rem",
      }}
    >
      <CheckCircleBlue />
      <Typography12px400 variant="body2">{label}</Typography12px400>
    </Stack>
  );
};

const Typography25px600Black = styled(Typography)`
  font-size: 25px;
  font-weight: 600;
  color: #000;
  margin: 0;
`;
const Typography12px500Black = styled(Typography)`
  font-size: 12px;
  font-weight: 500;
  color: #000;
`;

const Typography12px500Gray = styled(Typography12px500Black)`
  color: #8a8c91;
`;

const Typography12px500White = styled(Typography12px500Black)`
  color: #fff;
`;

const Typography12px400 = styled(Typography)`
  font-size: 12px;
  font-weight: 400;
  color: #000;
`;

function Subscription() {
  const { t } = useTranslation();
  const onClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const prices = usePrices();
  return (
    <Stack sx={{ alignItems: "center", marginY: "5rem" }}>
      <FloatingCard elevation={1}>
        <Stack
          sx={{
            flexDirection: { xs: "column", md: "row" },
            justifyContent: "center",
            rowGap: "1.5rem",
            columnGap: "1.5rem",
            margin: "2rem 3rem",
          }}
        >
          <Stack
            sx={{ textAlign: "center", marginRight: "1rem", rowGap: "2rem" }}
          >
            <Box>
              <Typography25px600Black variant="h3">
                {t("price-monthly")}
              </Typography25px600Black>
              <Typography12px500Gray variant="body2">
                {t("price-bill-cancellation")}
              </Typography12px500Gray>
            </Box>
            <Stack
              sx={{
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                columnGap: "1rem",
              }}
            >
              <Stack>
                <Typography25px600Black>
                  {prices.localeSubscriptionPrice}
                </Typography25px600Black>
                <Typography12px500Black variant="body2" color="#8A8C91">
                  {t("price-48-trial")}
                </Typography12px500Black>
              </Stack>
              <Stack
                sx={{
                  justifyContent: "center",
                  textAlign: "left",
                  width: "80px",
                  height: "24px",
                  position: "relative",
                  backgroundRepeat: "no-repeat",
                }}
              >
                <svg
                  viewBox="0 0 81 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    zIndex: 1,
                  }}
                >
                  <path
                    d="M1 21V3C1 1.89543 1.89543 1 3 1H66.3083C66.7564 1 67.1915 1.1505 67.5439 1.42736L78.9985 10.4274C80.0176 11.2281 80.0176 12.7719 78.9985 13.5726L67.5439 22.5726C67.1915 22.8495 66.7564 23 66.3083 23H3C1.89543 23 1 22.1046 1 21Z"
                    fill="#2F54EB"
                    stroke="#2F54EB"
                  />
                </svg>
                <Typography12px500White
                  variant="body2"
                  sx={{
                    marginLeft: "0.7rem",
                    position: "relative",
                    zIndex: 2,
                  }}
                >
                  {t("price-48-hours")}
                </Typography12px500White>
              </Stack>
              <Stack>
                <Typography25px600Black>
                  {prices.localeRebillPrice}
                </Typography25px600Black>
                <Typography12px500Black variant="body2">
                  {t("price-per-month")}
                </Typography12px500Black>
              </Stack>
            </Stack>
            <Box>
              <Button
                sx={{
                  pb: 1,
                  pt: 1,
                  pl: 4,
                  pr: 4,
                  width: "auto",
                }}
                onClick={onClick}
                variant="contained"
                disableElevation
              >
                {t("price-pay-now")}
              </Button>
            </Box>
          </Stack>
          <Stack rowGap="1rem">
            <ListItem label={t("price-list-one")} />
            <ListItem label={t("price-list-two")} />
            <ListItem label={t("price-list-three")} />
            <ListItem label={t("price-list-four")} />
            <ListItem label={t("price-list-five")} />
            <ListItem label={t("price-list-six")} />
          </Stack>
        </Stack>
      </FloatingCard>
    </Stack>
  );
}

export default Subscription;
