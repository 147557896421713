import useTranslation from "@cospex/client/hooks/useTranslation";
import { FloatingCard } from "@cospex/client/parcel/components/Layout";
import { ArrowBackIos } from "@mui/icons-material";
import { Stack, Typography } from "@mui/material";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

interface IParcelInfoError {
  id: string;
}

export default function ParcelInfoError({ id }: IParcelInfoError) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <>
      <FloatingCard sx={{ marginBottom: 5 }}>
        <Typography variant="h3" color="primary.main" mb={3}>
          {t("dashboard-parcel-info-general-title")}
        </Typography>
        <Typography variant="h4">
          {t("dashboard-parcel-error-title")}
        </Typography>
        <Typography variant="h5">{id}</Typography>
        <Typography variant="body2" mt={2} sx={{ whiteSpace: "pre-line" }}>
          {t("dashboard-parcel-error-description")}
        </Typography>
        <Stack direction="row" spacing={2} mt={2} justifyContent="center">
          <Button
            variant="contained"
            color="primary"
            size="large"
            startIcon={<ArrowBackIos />}
            onClick={() => navigate("/dashboard")}
          >
            {t("dashboard-parcel-back-button")}
          </Button>
        </Stack>
      </FloatingCard>
    </>
  );
}
