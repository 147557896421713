import useTranslation from "@cospex/client/hooks/useTranslation";
import CenteredCard, {
  CenteredCardSubtitle,
  CenteredCardSuccessBox,
  CenteredCardTitle,
} from "@cospex/client/parcel/components/CenteredCard";
import { Label } from "@cospex/client/parcel/components/Typography";
import { zodResolver } from "@hookform/resolvers/zod";
import { Alert, Button, Stack } from "@mui/material";
import TextField from "@mui/material/TextField";
import { useMutation } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";

const validationSchema = z.object({
  email: z.string().min(1, "required").email("login-email-invalid"),
});

type UnsubscribeData = {
  email: string;
};

const Unsubscribe = () => {
  const { t } = useTranslation();
  const { control, handleSubmit } = useForm<UnsubscribeData>({
    resolver: zodResolver(validationSchema),
  });
  const recaptchaRef = useRef<ReCAPTCHA>(null);

  const [unsubscribedSuccessfully, setUnsubscribedSuccessfully] =
    useState(false);

  const [unsubscribedError, setUnsubscribedError] = useState<
    string | undefined
  >("");

  const mutation = useMutation({
    mutationFn: async ({ email }: UnsubscribeData) => {
      if (!recaptchaRef.current) {
        throw new Error("reCAPTCHA not loaded");
      }
      const reCaptchaResult = await recaptchaRef.current.executeAsync();
      return axios.post(
        "/api/unsubscribe",
        {
          formData: {
            email,
            ["g-recaptcha-response"]: reCaptchaResult,
          },
        },
        {
          headers: { "Content-Type": "application/json" },
        }
      );
    },
    onSuccess: ({ data: { unsubscribed } }) => {
      if (unsubscribed) {
        setUnsubscribedSuccessfully(true);
      }
    },
    onError: (error: AxiosError<{ error: string }>) => {
      setUnsubscribedError(error.response?.data.error);
    },
  });

  const autoError = (message: string) => {
    return message.toLocaleLowerCase();
  };

  return (
    <>
      <CenteredCard>
        <CenteredCardSuccessBox
          show={unsubscribedSuccessfully}
          textComponent={t("unsubscription-successful")}
        />
        <Label>{t("unsubscribe-label")}</Label>
        <CenteredCardTitle text={t("unsubscribe-title")} />
        <CenteredCardSubtitle text={t("unsubscribe-subtitle")} />
        <form
          onSubmit={handleSubmit((data: UnsubscribeData) =>
            mutation.mutate(data)
          )}
        >
          <Stack spacing={2} sx={{ width: "100%" }}>
            <Controller
              name="email"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  label={t("login-email")}
                  error={!!fieldState.error}
                  helperText={
                    fieldState.error?.message &&
                    t(autoError(fieldState.error.message))
                  }
                  variant="outlined"
                  fullWidth
                />
              )}
            />
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey={import.meta.env.VITE_GOOGLE_RECAPTCHA_SITE_KEY}
              size="invisible"
              style={{
                margin: 0,
              }}
            />
            <Button
              disableElevation
              type="submit"
              variant="contained"
              color="primary"
              sx={{ width: "100%" }}
            >
              {t("cancel-subscription")}
            </Button>
            {unsubscribedError && (
              <Alert severity="error">{t(unsubscribedError)}</Alert>
            )}
          </Stack>
        </form>
      </CenteredCard>
    </>
  );
};

export default Unsubscribe;
