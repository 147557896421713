import { Label } from "@cospex/client/parcel/components/Typography";
import { Box, Card, SxProps, Theme, styled } from "@mui/material";
import { Container, Typography, useTheme } from "@mui/material";

const halfFilledBg = (color: string) => {
  return `background: linear-gradient(0deg, ${color}, 50%, ${color}, 50%, white, 100%, white)`;
};

type WrapperProps = {
  children: React.ReactNode;
};
interface SectionProps {
  filled?: boolean;
  noPaddingTop?: boolean;
  noPaddingBottom?: boolean;
  children: React.ReactNode;
}

interface SectionElementProps {
  filled: SectionProps["filled"];
  noPaddingBottom: SectionProps["noPaddingBottom"];
  noPaddingTop?: SectionProps["noPaddingBottom"];
}

interface FloatingCardProps extends WrapperProps {
  elevation?: number;
  className?: string;
  sx?: SxProps<Theme>;
}

const SectionElement = styled("section")<SectionElementProps>`
  ${({ theme, filled, noPaddingBottom, noPaddingTop }) => `
    overflow: hidden;
    padding: ${noPaddingTop ? 0 : "5rem"} 0 ${noPaddingBottom ? 0 : "5rem"};
    ${filled && halfFilledBg(theme.palette.grey[100])};
    ${theme.breakpoints.down("md")} {
      padding: ${noPaddingTop ? 0 : "3rem"} 0 ${noPaddingBottom ? 0 : "3rem"};
    }
    ${theme.breakpoints.down("sm")} {
      padding: ${noPaddingTop ? 0 : "2rem"} 0 ${noPaddingBottom ? 0 : "2rem"};
    }
  `}
`;

const CardStepLabelElement = styled("div")`
  ${({ theme }) => `
		width: 3rem;
		height: 3rem;
		display: inline-flex;
		font-size: 1.5rem;
		font-weight: 600;
		align-items: center;
		justify-content: center;
		background-color: ${theme.palette.grey[100]};
		margin-bottom: 1rem;
	`}
`;

const getElevationShadow = (elevation: number) => {
  switch (elevation) {
    case 1:
      return "0px 4px 10px rgba(0, 0, 0, 0.25)";
    case 2:
      return "0px 5px 20px rgba(0, 0, 0, 0.1)";
    case 3:
      return "0px 8px 30px rgba(0, 0, 0, 0.07)";
    default:
      return "0px 1px 2px rgba(0, 0, 0, 0.07)";
  }
};

const FloatingCardElement = styled(Card)<{
  elevation: number;
}>`
  padding: 1.5rem;
  box-shadow: ${({ elevation }) => getElevationShadow(elevation)};
`;

export function Section({
  filled,
  noPaddingTop,
  noPaddingBottom,
  children,
}: SectionProps) {
  return (
    <SectionElement {...{ filled, noPaddingTop, noPaddingBottom }}>
      <Container>{children}</Container>
    </SectionElement>
  );
}

export function SectionSubtitle({ children }: WrapperProps) {
  return (
    <Box sx={{ textAlign: "center" }}>
      <Label>{children}</Label>
    </Box>
  );
}

export function SectionTitle({ children }: WrapperProps) {
  return (
    <Box sx={{ textAlign: "center" }}>
      <Typography variant="h2" sx={{ mb: 5 }}>
        {children}
      </Typography>
    </Box>
  );
}

export function CardStepLabel({ children }: WrapperProps) {
  return <CardStepLabelElement>{children}</CardStepLabelElement>;
}

export function ParcelLocatorContainer({ children }: WrapperProps) {
  return <Box sx={{ maxWidth: 450, width: "100%" }}>{children}</Box>;
}

export function CarouselLogo({ src }: { src: string }) {
  const theme = useTheme();
  return (
    <Box
      sx={{
        borderRadius: ".5rem",
        p: 2,
        backgroundColor: theme.palette.grey[100],
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        overflow: "hidden",
      }}
    >
      <img src={src} alt="logo" style={{ maxHeight: 36 }} />
    </Box>
  );
}

export function Info({ children }: WrapperProps) {
  return (
    <Box sx={{ padding: "0 5%", margin: "5% auto", maxWidth: "1000px" }}>
      {children}
    </Box>
  );
}

export function FloatingCard({
  children,
  elevation = 2,
  className,
  sx,
}: FloatingCardProps) {
  return (
    <FloatingCardElement className={className} sx={sx} elevation={elevation}>
      {children}
    </FloatingCardElement>
  );
}
