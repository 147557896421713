import useTranslation from "@cospex/client/hooks/useTranslation";
import CenteredCard, {
  CenteredCardSubmit,
  CenteredCardSubtitle,
  CenteredCardTitle,
} from "@cospex/client/parcel/components/CenteredCard";
import { Label } from "@cospex/client/parcel/components/Typography";
import { zodResolver } from "@hookform/resolvers/zod";
import { Alert, Stack, TextField } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import axios, { isAxiosError } from "axios";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { z } from "zod";

type ResetPasswordData = {
  firstPass: string;
  secondPass: string;
};

const validationSchema = z.object({
  firstPass: z.string().min(4, "update-pass-min"),
  secondPass: z.string().min(4, "update-pass-min"),
});

export default function UpdatePassword() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const { handleSubmit, control } = useForm<ResetPasswordData>({
    resolver: zodResolver(validationSchema),
    defaultValues: {
      firstPass: "",
      secondPass: "",
    },
  });
  const [resetError, setErrMsg] = useState("");

  const mutation = useMutation({
    mutationFn: (variables: { firstPass: string; secondPass: string }) => {
      return axios.post(
        "/api/reset-password",
        JSON.stringify({
          formData: {
            token: token,
            plainPassword: {
              first: variables.firstPass,
              second: variables.secondPass,
            },
          },
        }),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    },
    onSuccess: () => {
      navigate("/login", { replace: true });
    },
    onError(error) {
      if (isAxiosError(error)) {
        setErrMsg(error.response?.data.error);
      }
    },
  });
  const onSubmit = (data: ResetPasswordData) => mutation.mutate(data);
  return (
    <CenteredCard>
      <Label>{t("update-pass-label")}</Label>
      <CenteredCardTitle text={t("update-pass-title")} />
      <CenteredCardSubtitle text={t("update-pass-subtitle")} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={2} sx={{ width: "100%" }}>
          <Controller
            name="firstPass"
            control={control}
            defaultValue=""
            render={({ field, formState }) => (
              <TextField
                {...field}
                label={t("update-pass")}
                type="password"
                fullWidth
                error={!!formState.errors["firstPass"]}
                helperText={
                  formState.errors["secondPass"]?.message &&
                  t(formState.errors["secondPass"].message)
                }
              />
            )}
          />
          <Controller
            name="secondPass"
            control={control}
            defaultValue=""
            render={({ field, formState }) => (
              <TextField
                {...field}
                label={t("update-pass-confirm")}
                type="password"
                fullWidth
                error={!!formState.errors["secondPass"]}
                helperText={
                  formState.errors["secondPass"]?.message &&
                  t(formState.errors["secondPass"].message)
                }
              />
            )}
          />

          <CenteredCardSubmit
            loading={mutation.isLoading}
            text={t("update-pass-button")}
          />
          {resetError && <Alert severity="error">{resetError}</Alert>}
        </Stack>
      </form>
    </CenteredCard>
  );
}
