import useTranslation from "@cospex/client/hooks/useTranslation";
import {
  SectionSubtitle,
  SectionTitle,
} from "@cospex/client/parcel/components/Layout";
import PriceComponent from "@cospex/client/parcel/components/PriceComponent";
import { Container } from "@mui/material";
import { useNavigate } from "react-router-dom";

function Prices() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <Container>
      <SectionSubtitle>{t("home-pricing-subtitle")}</SectionSubtitle>
      <SectionTitle>{t("home-pricing-title")}</SectionTitle>
      <PriceComponent action={() => navigate("/")} />
    </Container>
  );
}

export default Prices;
