import useTranslation from "@cospex/client/hooks/useTranslation";
import CenteredCard, {
  CenteredCardSubmit,
  CenteredCardSubtitle,
  CenteredCardTitle,
} from "@cospex/client/parcel/components/CenteredCard";
import { Label } from "@cospex/client/parcel/components/Typography";
import { zodResolver } from "@hookform/resolvers/zod";
import { Alert, Stack, TextField } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";

type ResetPasswordData = {
  email: string;
};

const validationSchema = z.object({
  email: z.string().email("reset-pass-email-required").min(1),
});

const ResetPassword = () => {
  const { t } = useTranslation();
  const emailRef = useRef<HTMLInputElement>(null);
  const { control, handleSubmit } = useForm<ResetPasswordData>({
    resolver: zodResolver(validationSchema),
  });
  const [resetSuccessful, setResetSuccessful] = useState(false);
  const [resetError, setResetError] = useState<null | string>();

  const mutation = useMutation({
    mutationFn: ({ email }: ResetPasswordData) => {
      return axios.post(
        "/api/request-password-email",
        JSON.stringify({ email }),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    },
    onSuccess: () => {
      setResetSuccessful(true);
    },
    onError: ({ message }) => {
      setResetError(message);
    },
  });

  useEffect(() => {
    emailRef.current?.focus();
  }, []);

  return (
    <>
      <CenteredCard>
        <Label>{t("reset-pass-label")}</Label>
        <CenteredCardTitle text={t("reset-pass-title")} />
        <CenteredCardSubtitle text={t("reset-pass-subtitle")} />
        <form
          onSubmit={handleSubmit((data: ResetPasswordData) =>
            mutation.mutate(data)
          )}
        >
          <Stack spacing={2} sx={{ width: "100%" }}>
            <Controller
              name="email"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  label={t("login-email")}
                  error={!!fieldState.error}
                  helperText={
                    fieldState.error?.message && t(fieldState.error?.message)
                  }
                  variant="outlined"
                  fullWidth
                />
              )}
            />
            <CenteredCardSubmit
              loading={mutation.isLoading}
              text={t("reset-pass-title")}
            />
            {resetSuccessful && (
              <Alert severity="success">{t("reset-pass-sent")}</Alert>
            )}
            {resetError && <Alert severity="error">{resetError}</Alert>}
          </Stack>
        </form>
      </CenteredCard>
    </>
  );
};

export default ResetPassword;
