import { withTransientProps } from "@cospex/client/helpers";
import usePrices from "@cospex/client/hooks/usePrices";
import useTranslation from "@cospex/client/hooks/useTranslation";
import { CheckCircle } from "@mui/icons-material";
import {
  Button,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
  styled,
} from "@mui/material";

const StyledBox = styled(Stack, withTransientProps)<{ $isTrial?: boolean }>`
  ${({ theme, $isTrial }) => `
    border-radius: 10px;
    padding: 2.5rem;
    width: 100%;
    row-gap: .5rem;
    ${
      $isTrial
        ? `
            background: ${theme.palette.primary.main};
            .MuiTypography-root  {
              color: white;
            }
            .MuiSvgIcon-root {
              color: white;
            }
            .MuiDivider-root {
              border-color: rgba(255,255,255,.2);
            }
            .MuiButton-root {
              background: white;
              color: black;
              &:hover {
                background: ${theme.palette.grey[200]};
              }
            }
          `
        : `
            .MuiSvgIcon-root {
              color: ${theme.palette.primary.main};
            }
            border: 1px solid ${theme.palette.grey[300]};
          `
    }
  `}
`;

const PlanContentBox = ({
  heading,
  desc,
  descMinor,
}: {
  heading: string;
  desc: string;
  descMinor: string;
}) => {
  return (
    <>
      <Typography variant="h2" sx={{ mb: 1 }}>
        {heading}
      </Typography>
      <Typography variant="h3" display="flex" alignItems="center">
        {desc}
        <span
          style={{
            paddingLeft: ".25rem",
            fontSize: "1rem",
            fontWeight: 400,
            opacity: 0.8,
            transform: "translateY(1px)",
          }}
        >
          / {descMinor}
        </span>
      </Typography>
    </>
  );
};

const ItemStack = ({ items }: { items: string[] }) => {
  return (
    <List dense={true}>
      {items.map((item: string, index: number) => {
        return (
          <ListItem key={index} sx={{ pl: 0 }}>
            <ListItemIcon sx={{ minWidth: "auto", mr: 2 }}>
              <CheckCircle />
            </ListItemIcon>
            <ListItemText primary={item} />
          </ListItem>
        );
      })}
    </List>
  );
};

export default function PriceComponent({ action }: { action?: () => void }) {
  const { t } = useTranslation();

  const trailItems: string[] = [
    t("trial-advantage-one"),
    t("trial-advantage-two"),
    t("trial-advantage-three"),
    t("trial-advantage-four"),
  ];

  const monthlyItems: string[] = [
    t("monthly-plan-one"),
    t("monthly-plan-two"),
    t("monthly-plan-three"),
    t("monthly-plan-four"),
    t("monthly-plan-five"),
    t("monthly-plan-six"),
    t("monthly-plan-seven"),
  ];

  const prices = usePrices();
  return (
    <Grid container spacing={5} justifyContent="center">
      <Grid item xs={12} md={5} display="flex" alignItems="stretch">
        <StyledBox $isTrial>
          <PlanContentBox
            heading={t("trial-offer")}
            desc={prices.localeSubscriptionPrice}
            descMinor={t("trial-offer-duration")}
          />
          <ItemStack items={trailItems} />
          <Divider sx={{ mb: 2 }} />
          <Button sx={{ p: 1.5 }} onClick={() => action?.()}>
            {t("trial-offer-cta")}
          </Button>
        </StyledBox>
      </Grid>
      <Grid item xs={12} md={5} display="flex" alignItems="stretch">
        <StyledBox>
          <PlanContentBox
            heading={t("monthly-plan")}
            desc={prices.localeRebillPrice}
            descMinor={t("monthly-plan-duration")}
          />
          <ItemStack items={monthlyItems} />
        </StyledBox>
      </Grid>
    </Grid>
  );
}
