import useAuth from "@cospex/client/hooks/useAuth";
import useTranslation from "@cospex/client/hooks/useTranslation";
import CenteredCard, {
  CenteredCardSubmit,
  CenteredCardTitle,
} from "@cospex/client/parcel/components/CenteredCard";
import { Label } from "@cospex/client/parcel/components/Typography";
import { zodResolver } from "@hookform/resolvers/zod";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Alert,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { MouseEvent, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { z } from "zod";

const validationSchema = z.object({
  email: z.string().email("login-email-invalid"),
  password: z.string().min(4, "login-password-min"),
});

type LoginData = {
  email: string;
  password: string;
};

const Login = () => {
  const { t } = useTranslation();
  const { control, handleSubmit } = useForm<LoginData>({
    resolver: zodResolver(validationSchema),
  });
  const { signin } = useAuth();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState<null | string>(null);

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const handleMouseDownPassword = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const mutation = useMutation({
    mutationFn: ({ email, password }: LoginData) => {
      return axios.post(
        "/api/login",
        { email, password },
        {
          withCredentials: true,
          headers: { "Content-Type": "application/json" },
        }
      );
    },
    onSuccess: (res, { email }) => {
      const token = res?.data?.token || "";
      const refreshToken = res?.data?.refresh_token || "";
      signin(email, token, refreshToken);
      navigate("/dashboard", { replace: true });
    },
    onError(res: any) {
      const error = res.response?.data?.error || res.message;
      setError(error);
    },
  });

  return (
    <CenteredCard>
      <Label>{t("login-label")}</Label>
      <CenteredCardTitle text={t("login-title")} />
      <form onSubmit={handleSubmit((data: LoginData) => mutation.mutate(data))}>
        <Stack spacing={2} sx={{ width: "100%", pt: 2 }}>
          <Controller
            name="email"
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                label={t("login-email")}
                error={!!fieldState.error}
                helperText={
                  fieldState.error?.message && t(fieldState.error?.message)
                }
                variant="outlined"
                fullWidth
              />
            )}
          />
          <Controller
            name="password"
            control={control}
            render={({ field, fieldState }) => (
              <FormControl
                sx={{ mb: 1 }}
                variant="outlined"
                error={!!fieldState.error}
              >
                <InputLabel htmlFor="outlined-adornment-password">
                  {t("login-password")}
                </InputLabel>
                <OutlinedInput
                  {...field}
                  id="outlined-adornment-password"
                  type={showPassword ? "text" : "password"}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label={t("login-password")}
                />
                {fieldState.error?.message && (
                  <FormHelperText>
                    {t(fieldState.error?.message)}
                  </FormHelperText>
                )}
              </FormControl>
            )}
          />
          <Typography variant="body2" sx={{ mb: 2 }}>
            {t("login-forgot-password")}{" "}
            <Typography
              variant="body2"
              component="a"
              href="/reset-password"
              sx={{ cursor: "pointer" }}
            >
              {t("login-reset-password")}
            </Typography>
          </Typography>

          <CenteredCardSubmit
            loading={mutation.isLoading}
            text={t("login-now-button")}
          />
          {error && <Alert severity="error">{t(error)}</Alert>}
        </Stack>
      </form>
    </CenteredCard>
  );
};

export default Login;
