import { CheckCircleOutline } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Typography } from "@mui/material";
import { Box } from "@mui/material";
import { useTheme } from "@mui/material";

export default function CenteredCard({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: "5vw 2rem",
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          maxWidth: "716px",
          padding: "5vw",
          borderRadius: "0.5rem",
          boxShadow: "0 0 0.5rem 0.1rem rgba(0, 0, 0, 0.1)",
          position: "relative",
          overflow: "hidden",
        }}
      >
        <Box sx={{ width: "100%", textAlign: "center" }}>{children}</Box>
      </Box>
    </Box>
  );
}

export function CenteredCardTitle({ text }: { text: string }) {
  return (
    <Typography
      variant="h2"
      sx={{
        fontWeight: 600,
      }}
    >
      {text}
    </Typography>
  );
}

export function CenteredCardSubtitle({ text }: { text: string }) {
  return (
    <Typography
      variant="body1"
      sx={{
        mb: "2vw",
      }}
    >
      {text}
    </Typography>
  );
}

export function CenteredCardSuccessBox({
  textComponent,
  show,
}: {
  textComponent: React.ReactNode;
  show: boolean;
}) {
  const theme = useTheme();
  return (
    <Box
      sx={{
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        background: "white",
        transform: show ? "translateX(0)" : "translateX(-100%)",
        transition: "transform 1s cubic-bezier(0.16, 1, 0.3, 1)",
        zIndex: 100,
      }}
    >
      <CheckCircleOutline color="success" fontSize="large" sx={{ mb: 2 }} />
      <Typography
        variant="body1"
        sx={{ color: theme.palette.success.main, fontWeight: 600 }}
      >
        {textComponent}
      </Typography>
    </Box>
  );
}

export function CenteredCardSubmit({
  text,
  loading,
}: {
  text: string;
  loading: boolean;
}) {
  return (
    <LoadingButton
      disableElevation
      type="submit"
      variant="contained"
      color="primary"
      sx={{ width: "100%" }}
      loading={loading}
    >
      <span>{text}</span>
    </LoadingButton>
  );
}
