import ParcelInfoError from "@cospex/client/parcel/components/ParcelInfoError";
import { redirect, useParams } from "react-router-dom";

export default function ParcelErrorPage() {
  const { id } = useParams<{ id: string }>();
  if (!id) {
    return redirect("/dashboard/parcels");
  }
  return <ParcelInfoError id={id} />;
}
