import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function RedirectToParcels() {
  const navigate = useNavigate();

  useEffect(() => {
    navigate("/dashboard/parcels", { replace: true });
  }, []);

  return null;
}
