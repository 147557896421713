import { Box, Grid, Typography } from "@mui/material";

export default function CoverageItem({
  icon,
  title,
}: {
  icon: string;
  title: string;
}) {
  return (
    <Grid item xs={12} sm={6}>
      <Box sx={{ mb: 1 }}>
        <Box
          component="img"
          src={icon}
          alt={title}
          sx={{
            height: 54,
            width: "auto",
            display: "block",
          }}
        />
      </Box>
      <Typography variant="body2" gutterBottom>
        {title}
      </Typography>
    </Grid>
  );
}
