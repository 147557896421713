import DashboardLayout from "@cospex/client/parcel/components/DashboardLayout";

export default function Layout() {
  return (
    <DashboardLayout
      languages={[
        { value: "en", label: "EN 🇬🇧" },
        { value: "fr", label: "FR 🇫🇷" },
        { value: "it", label: "IT 🇮🇹" },
      ]}
    />
  );
}
