import LogosImagePath from "@/img/carrier_logos.svg";
import CoverageImagePath from "@/img/coverage_graphic.svg";
import DhlLogo from "@/img/dhl_logo.svg";
import DplLogo from "@/img/dpl_logo.png";
import FedexLogo from "@/img/fedex_logo.svg";
import HeroImageA from "@/img/hero_image_a.svg";
import HeroImageB from "@/img/hero_image_b.svg";
import HeroImageC from "@/img/hero_image_c.svg";
import CoverageIcon3 from "@/img/infographic_24.svg";
import CoverageIcon2 from "@/img/infographic_52.svg";
import CoverageIcon4 from "@/img/infographic_365.svg";
import CoverageIcon1 from "@/img/infographic_500.svg";
import UpsLogo from "@/img/ups_logo.svg";
import Carousel, { CarouselItem } from "@cospex/client/components/Carousel";
import Counter from "@cospex/client/components/Counter";
import FeaturesList from "@cospex/client/components/FeaturesList";
import ReviewCarousel from "@cospex/client/components/ReviewCarousel";
import useAuth from "@cospex/client/hooks/useAuth";
import useTranslation from "@cospex/client/hooks/useTranslation";
import CoverageItem from "@cospex/client/parcel/components/CoverageItem";
import {
  CardStepLabel,
  CarouselLogo,
  ParcelLocatorContainer,
  Section,
  SectionSubtitle,
  SectionTitle,
} from "@cospex/client/parcel/components/Layout";
import ParcelLocator from "@cospex/client/parcel/components/ParcelLocator";
import PriceComponent from "@cospex/client/parcel/components/PriceComponent";
import { scrollToTop } from "@cospex/client/parcel/components/ScrollToTop";
import TiltPlane from "@cospex/client/parcel/components/TiltPane";
import { Label } from "@cospex/client/parcel/components/Typography";
import PublicIcon from "@mui/icons-material/Public";
import { Box, Card, Grid, Typography, useTheme } from "@mui/material";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function Home() {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const scaledAtDesktop = {
    transform: {
      md: "scale(.85)",
    },
  };
  const { session } = useAuth();
  const halfImage = {
    width: "100%",
    maxWidth: {
      md: 400,
      xs: 300,
    },
  };

  const onTrack = () => {
    if (session?.email) {
      navigate("/dashboard");
      return;
    }
    navigate("/payment");
  };

  useEffect(() => {
    setTimeout(() => {
      document.getElementById("trackingNumber")!.focus();
    }, 0);
  }, []);
  return (
    <>
      <Section noPaddingTop>
        <Grid container spacing={{ md: 5 }}>
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                p: {
                  md: 5,
                  sm: 3,
                  xs: 1,
                },
              }}
            >
              <Typography variant="h1" gutterBottom>
                {t("home-title")}
                <span style={{ color: theme.palette.primary.main }}>
                  {" "}
                  {t("home-title-addendum")}
                </span>
              </Typography>
              <Typography variant="body2" sx={{ mb: 3 }}>
                {t("home-description")}
              </Typography>
              <ParcelLocator onTrack={onTrack} />
              <FeaturesList
                sx={{
                  justifyContent: "flex-start",
                }}
                featureKeys={[t("home-features-1"), t("home-features-2")]}
              />
              <Counter>{t("homepage-counter")}</Counter>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box display="flex" alignItems="center" justifyContent="center">
              <Box
                sx={{
                  width: "100%",
                  height: "auto",
                  aspectRatio: "1/1",
                  maxWidth: 550,
                  position: "relative",
                }}
              >
                <TiltPlane>
                  <Box
                    component="img"
                    src={HeroImageA}
                    alt="Hero Image"
                    sx={{ width: "100%", height: "100%" }}
                  />
                  <Box
                    component="img"
                    src={HeroImageB}
                    alt="Hero Image"
                    sx={{ width: "100%", height: "100%" }}
                  />
                  <Box
                    component="img"
                    src={HeroImageC}
                    alt="Hero Image"
                    sx={{ width: "100%", height: "100%" }}
                  />
                  <Box
                    sx={{ width: "100%", height: "100%", position: "relative" }}
                  >
                    <Box
                      sx={{
                        position: "absolute",
                        left: "28%",
                        top: "92.3%",
                        color: "black",
                        fontWeight: 600,
                        fontFamily: "sans-serif",
                        fontSize: {
                          xs: 12,
                          md: 14,
                        },
                      }}
                    >
                      {t("homepage-graphic-message")}
                    </Box>
                  </Box>
                </TiltPlane>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Section>
      <Section noPaddingTop>
        <SectionSubtitle>{t("home-testimonials-subtitle")}</SectionSubtitle>
        <SectionTitle>{t("home-testimonials-title")}</SectionTitle>
        <ReviewCarousel />
      </Section>
      <Section filled>
        <SectionTitle>{t("home-how-it-works")}</SectionTitle>
        <Grid container spacing={1} alignItems="stretch">
          <Grid item xs={12} md={4} display="flex" alignItems="stretch">
            <Card variant="outlined" sx={scaledAtDesktop}>
              <CardStepLabel>1</CardStepLabel>
              <Typography variant="h3" gutterBottom>
                {t("home-how-it-works-step-1-title")}
              </Typography>
              <Typography variant="body2">
                {t("home-how-it-works-step-1-description")}
              </Typography>
            </Card>
          </Grid>
          <Grid item xs={12} md={4} display="flex" alignItems="stretch">
            <Card variant="outlined">
              <CardStepLabel>2</CardStepLabel>
              <Typography variant="h3" gutterBottom>
                {t("home-how-it-works-step-2-title")}
              </Typography>
              <Typography variant="body2">
                {t("home-how-it-works-step-2-description")}
              </Typography>
            </Card>
          </Grid>
          <Grid item xs={12} md={4} display="flex" alignItems="stretch">
            <Card variant="outlined" sx={scaledAtDesktop}>
              <CardStepLabel>3</CardStepLabel>
              <Typography variant="h3" gutterBottom>
                {t("home-how-it-works-step-3-title")}
              </Typography>
              <Typography variant="body2">
                {t("home-how-it-works-step-3-description")}
              </Typography>
            </Card>
          </Grid>
        </Grid>
      </Section>
      <Section>
        <Grid container spacing={8}>
          <Grid
            item
            xs={12}
            md={6}
            display="flex"
            justifyContent="center"
            alignContent="center"
          >
            <Box
              component="img"
              src={LogosImagePath}
              alt="Carrier Logos"
              sx={halfImage}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            flexDirection="column"
            display="flex"
            justifyContent="center"
            alignContent="center"
          >
            <Label>{t("home-carrier-logos-subtitle")}</Label>
            <Typography variant="h2" gutterBottom>
              {t("home-carrier-logos-title")}
            </Typography>
            <Typography variant="body2" sx={{ pb: 3 }}>
              {t("home-carrier-logos-description")}
            </Typography>
            <ParcelLocatorContainer>
              <ParcelLocator onTrack={onTrack} />
            </ParcelLocatorContainer>
          </Grid>
        </Grid>
      </Section>
      <Section filled noPaddingBottom>
        <SectionSubtitle>
          {t("home-available-carriers-subtitle")}
        </SectionSubtitle>
        <SectionTitle>{t("home-available-carriers-title")}</SectionTitle>
        <Carousel>
          <CarouselItem>
            <Typography variant="h3" gutterBottom>
              {t("carriers-carousel-item-1-title")}
            </Typography>
            <Typography variant="body2" mb={2}>
              {t("carriers-carousel-item-1-description")}
            </Typography>
            <CarouselLogo src={DhlLogo} />
          </CarouselItem>
          <CarouselItem>
            <Typography variant="h3" gutterBottom>
              {t("carriers-carousel-item-2-title")}
            </Typography>
            <Typography variant="body2" mb={2}>
              {t("carriers-carousel-item-2-description")}
            </Typography>
            <CarouselLogo src={FedexLogo} />
          </CarouselItem>
          <CarouselItem>
            <Typography variant="h3" gutterBottom>
              {t("carriers-carousel-item-3-title")}
            </Typography>
            <Typography variant="body2" mb={2}>
              {t("carriers-carousel-item-3-description")}
            </Typography>
            <CarouselLogo src={DplLogo} />
          </CarouselItem>
          <CarouselItem>
            <Typography variant="h3" gutterBottom>
              {t("carriers-carousel-item-4-title")}
            </Typography>
            <Typography variant="body2" mb={2}>
              {t("carriers-carousel-item-4-description")}
            </Typography>
            <CarouselLogo src={UpsLogo} />
          </CarouselItem>
          <CarouselItem>
            <Typography variant="h3" gutterBottom>
              {t("carriers-carousel-item-5-title")}
            </Typography>
            <Typography variant="body2" mb={2}>
              {t("carriers-carousel-item-5-description")}
            </Typography>
            <Box display="flex" justifyContent="center">
              <Box
                sx={{
                  p: 1,
                  background: theme.palette.grey[100],
                  display: "inline-block",
                  lineHeight: 0,
                  borderRadius: "50%",
                  mb: 2,
                }}
              >
                <PublicIcon color="primary" sx={{ fontSize: 60 }} />
              </Box>
            </Box>
          </CarouselItem>
        </Carousel>
      </Section>
      <Section>
        <Grid container spacing={5}>
          <Grid
            item
            xs={12}
            sm={6}
            display="flex"
            justifyContent="center"
            alignContent="center"
          >
            <Box component="img" src={CoverageImagePath} sx={halfImage} />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            display="flex"
            justifyContent="center"
            alignContent="center"
            flexDirection="column"
          >
            <Label>{t("home-coverage-subtitle")}</Label>
            <Typography variant="h2" sx={{ mb: 6 }}>
              {t("home-coverage-title")}
            </Typography>
            <Box sx={{ maxWidth: 416, width: "100%" }}>
              <Grid container spacing={2}>
                <CoverageItem
                  icon={CoverageIcon1}
                  title={t("home-coverage-item-1-title")}
                />
                <CoverageItem
                  icon={CoverageIcon2}
                  title={t("home-coverage-item-2-title")}
                />
                <CoverageItem
                  icon={CoverageIcon3}
                  title={t("home-coverage-item-3-title")}
                />
                <CoverageItem
                  icon={CoverageIcon4}
                  title={t("home-coverage-item-4-title")}
                />
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Section>
      <Section>
        <SectionSubtitle>{t("home-pricing-subtitle")}</SectionSubtitle>
        <SectionTitle>{t("home-pricing-title")}</SectionTitle>
        <PriceComponent action={() => scrollToTop()} />
      </Section>
      <Section>
        <SectionTitle>{t("home-final-pitch-title")}</SectionTitle>
        <Box display="flex" justifyContent="center" alignItems="center">
          <ParcelLocatorContainer>
            <ParcelLocator onTrack={onTrack} />
          </ParcelLocatorContainer>
        </Box>
      </Section>
    </>
  );
}

export default Home;
