import { API_PATH_ANALYTICS } from "@cospex/client/constants";
import { getSourceParams, setItemWithExpiry } from "@cospex/client/helpers";
import useTranslation from "@cospex/client/hooks/useTranslation";
import CenteredCard from "@cospex/client/parcel/components/CenteredCard";
import { Alert, Container, Typography } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const FollowUp = () => {
  const { t } = useTranslation();
  const [error, setError] = useState<string | null>(null);
  const [dots, setDots] = useState<string>("");
  const navigate = useNavigate();
  const {
    email,
    utm_source,
    utm_medium,
    utm_campaign,
    gclid,
    fbclid,
    msclkid,
  } = getSourceParams();
  useEffect(() => {
    if (!email) {
      setError("followup-malformed-url");
      return;
    }
    if (!utm_source || !utm_medium) {
      throw new Error("No utm_source or utm_medium provided on followup");
    }
    axios
      .post(API_PATH_ANALYTICS, {
        email,
        utm_source,
        utm_medium,
        utm_campaign,
        gclid,
        fbclid,
        msclkid,
      })
      .then((res: any) => {
        const { trackingNumber, id } = res.data;
        setItemWithExpiry("onboardingNumber", trackingNumber);
        setItemWithExpiry("onboardingId", id);
        setItemWithExpiry("onboardingEmail", email);
        navigate("/payment");
      })
      .catch(() => {
        setError("followup-tracking-error");
      });
  }, []);

  useEffect(() => {
    const i = setInterval(() => {
      setDots((dots) => {
        if (dots.length === 3) {
          return "";
        }
        return dots + ".";
      });
    }, 750);
    return () => clearInterval(i);
  }, []);

  return (
    <Container>
      <CenteredCard>
        {error ? (
          <Alert severity="error">{t(error)}</Alert>
        ) : (
          <Typography variant="body1" mb={3}>
            {t("followup-body")}
            {dots}
          </Typography>
        )}
      </CenteredCard>
    </Container>
  );
};

export default FollowUp;
